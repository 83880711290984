import styled from "styled-components";
import {media} from "../variables";

export const TextElement = styled.div`
  font-size: 18px;
  margin-top: 40px;
  margin-left: 40px;
`

export const BoldText = styled.div`
  font-size: 24px;
  font-weight: bold;
`

export const DescriptionText = styled.div`
  ${media.tablet`
        padding-left: 10px;
    `}
`

export const Container = styled.div`
    margin: 1rem 1rem;
    position: relative;

  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

export const ChapterHeading = styled.h2`
    font-size: 36px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const RefImage = styled.img`
  width: 300px;
  margin: 0 auto;
  text-align: center;
  display: block;

  @media (max-width: 1024px) {
    width: 160px;
  }
`;

export const LineSVG = styled.svg`
  @media (max-width: 1024px) {
      display: none;
  }
`;

export const PromoContainer = styled(Container)`
    width: 1170px;
    padding: 0 10px;
    text-align: center;

    ${media.tablet`
      width: 100%;
      margin: 0;
      padding: 0 2px;
    `}
`;

export const CampSectionHeading = styled.h2`
    text-align: left;
    font-size: 36px;
    font-weight: 600;
    color: #333;
    margin-bottom: 40px;
    ${media.tablet`
        font-size: 30px;
        margin: 0;
    `}
`;


export const FeatureContainerTop = styled.div`
    display: grid;
    grid-template-columns: 4fr 6fr;
    margin: 60px 0;

    font-size: 18px;

    h3 {
        color: #000000;
    }

    p {
        color: #000000;
    }
    @media (max-width: 1124px) {
    justify-content: center;
  }
    ${media.desktop`
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  `}
`;

export const FeatureContainerBottom = styled.div`
    display: grid;
    font-size: 18px;

    &:first-of-type {
        grid-template-columns: 4fr 6fr;
        margin-bottom: 8rem;
    }

    grid-template-columns: 6fr 4fr;

    h3 {
        color: #000000;
    }

    p {
        color: #000000;
    }
    @media (max-width: 1124px) {
    justify-content: center;
  }
    ${media.desktop`
    display: flex;
    flex-wrap: wrap-reverse;
  `}
`;

export const FeatureTextContainer = styled.div`
    display: flex;
    position: relative;
    padding: 0 0 0 40px;
    margin-right: 0;
    flex-direction: column;
    justify-content: center;
    max-width: 440px;
    text-align: left;

     & > p {
            margin-right: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
     & > h3 {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-right: 10px;
        }

  ${media.mobile`
   padding: 0 0 0 0;
   &  h3 {
    font-size: 18px;

  }
  & > p {
    font-size: 16px;

  }
    `};
`;

export const FeatureTextContainerUnit = styled.div`
    margin: 20px 0 10px;
   font-size: 16px;
`;

export const FeatureImageContainer = styled.div`
    position: relative;
    max-width: 690px;
  
  > img {
    border-radius: 4px;
  }
`;

