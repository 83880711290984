import * as React from "react";
import Layout from '../../components/Layout'
import {MainText} from "../../components/MainComponents";
import {
    BoldText,
    CampSectionHeading,
    DescriptionText,
    FeatureContainerBottom,
    FeatureContainerTop,
    FeatureImageContainer,
    FeatureTextContainer,
    FeatureTextContainerUnit,
    PromoContainer,
} from "../../components/NaseAktivity/layout";
import {MainSection, TopPhotoFrame} from "../../components/common";
import {TunasecButton} from "../../components/Elements/Button";
import Helmet from "react-helmet";

// Text, ktory mozme este v ramci webu implementovat
//
// Důvodů, proč s námi spolupracovat, je vícero. Každý náš sponzor má možnost směřovat naše aktivity a vyjadřovat se k běhu a zaměření TunaSec.
// Taky dokážeme za příspěvky poskytnout přidanou hodnotu ve formě workshopů, základního bezpečnostního auditu nebo jiné formě, záleží na dohodě.
// Naši sponzoři jsou promování na událostech, které organizujeme jako partneři.

export default () => {
    const title = "Aktivity, kterým se věnujeme";

    return (
        <Layout>
            <Helmet titleTemplate={`${title} | TunaSec`}>
                {/* General tags */}
                <title>{title} | TunaSec</title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="bezpečnost, it security, it bezpečnost" />
                <meta property="og:title" content="Aktivity, kterým se věnujeme | TunaSec"/>
                <meta name="description" content="Víme, že pro změnu ve společnosti je nutné dělat činnosti a aktivity, které nás posunou blíž k naplnění naší vize." />
                <meta name="og:description" content="Víme, že pro změnu ve společnosti je nutné dělat činnosti a aktivity, které nás posunou blíž k naplnění naší vize." />
                <meta name="og:image" content="https://tunasec.com/img/og-images/nase-aktivity.png" />
            </Helmet>
            <TopPhotoFrame className="full-width-image margin-top-0">
                <MainText>{title}</MainText>
            </TopPhotoFrame>
            <MainSection className="section section--gradient container">
                <PromoContainer>
                    <CampSectionHeading>Aktivity, ve kterých se angažujeme</CampSectionHeading>
                    <FeatureContainerTop>
                        <FeatureTextContainer>
                            <FeatureTextContainerUnit>
                                <BoldText>Vzdělávání veřejnosti a škol</BoldText>
                                <DescriptionText>
                                    Náš cíl zvýšení povědomí v oblasti IT bezpečnosti naplňujeme hlavně <strong>vzděláváním v školách a jiných vzdělávacích institucích</strong>. Pravidelně se účastníme jako přednášející na různých konferencích v ČR i v zahraničí.
                                    Píšeme také <strong>blogové články pro širokou veřejnost</strong> s cílem zvýšení povědomí o základních konceptech v kyberbezpečnosti. <br/>
                                    <TunasecButton to={"/blog/"}>Přejít na blog</TunasecButton>
                                </DescriptionText>
                            </FeatureTextContainerUnit>
                        </FeatureTextContainer>
                        <FeatureImageContainer>
                            <img src={"/img/pages/tuna-workshop.jpg"} alt="Workshop v TunaSec"/>
                        </FeatureImageContainer>
                    </FeatureContainerTop>
                    <FeatureContainerBottom>
                        <FeatureImageContainer>
                            <img src={"/img/pages/firemni-bezpecnost-web.jpg"} alt="Firemní bezpečnost"/>
                        </FeatureImageContainer>
                        <FeatureTextContainer>
                            <FeatureTextContainerUnit>
                                <BoldText>Firemní kyberbezpečnost</BoldText>
                                <DescriptionText>
                                    Spolupracujeme s vícero firmami, kterým nahlašujeme chyby na jejich stránkách a infrastruktuře.<br/>
                                    Pro naše zákazníky poskytujeme <strong>bezpečnostní audit firemních systémů, penetrační testování a poradenství v oblasti IT bezpečnosti</strong>.<br/>
                                    <TunasecButton to={"/kontakt/"}>Poptat spolupráci</TunasecButton>
                                </DescriptionText>
                            </FeatureTextContainerUnit>
                        </FeatureTextContainer>
                    </FeatureContainerBottom>
                    <FeatureContainerTop>
                        <FeatureTextContainer>
                            <FeatureTextContainerUnit>
                                <BoldText>Workshopy a CTFs</BoldText>
                                <DescriptionText>
                                    Kromě vzdělávání veřejnosti se zaměřujeme na <strong>praktickou výuku kyberbezpečnosti / etického hackingu formou workshopů</strong>.<br/>
                                    Organizujeme také CTF soutěže (Capture The Flag) pro konference i námi organizované eventy.<br/>
                                </DescriptionText>
                                <TunasecButton to={"/kontakt/"}>Chci pomoct</TunasecButton>
                            </FeatureTextContainerUnit>
                        </FeatureTextContainer>
                        <FeatureImageContainer>
                            <img src={"/img/pages/workshop-web.jpg"} alt="Workshop web foto"/>
                        </FeatureImageContainer>
                    </FeatureContainerTop>
                </PromoContainer>
            </MainSection>
        </Layout>
    );
}
