import * as React from 'react';
import styled, {css} from 'styled-components';
import {media, ThemeDefinition} from '../variables';

export interface WrappedButtonProps {
    disabled?: boolean,
    to?: string,
    type?: "submit" | "reset" | "button",
    className?: string,
    style?: object,
    onClick?: (e?: any) => void,
    children: React.ReactNode,
    id?: string
    target?: string
    onAnimationEnd?: (e?: any) => void,
}

export const WrappedButton = ({disabled, to, children, target, ...props}: ButtonProps) => (
    to ?
        <a {...props} href={to} target={target}>{children}</a>
        : <button {...props}><SafariCompatWrapper>{children}</SafariCompatWrapper></button>
);

export interface ButtonProps extends WrappedButtonProps {
    theme?: ThemeDefinition
}

/**
 * @see https://github.com/jgthms/bulma/issues/727
 */
const SafariCompatWrapper = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%; height: 100%;
`;

export const ButtonStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: 0.6rem 1.8rem;
  ${media.mobile`
    padding: 1rem 1.5rem;
   `};

  white-space: nowrap;

  ${({theme}: ButtonProps) => theme ? css`
    color: ${theme.text};
    background: ${theme.color};
  ` : ''};

  ${({disabled, theme}: ButtonProps) => disabled
    ? css`pointer-events: none; opacity: 0.8; cursor: default;`
    : css`
        &:hover {
            box-shadow: 0 0.2rem 0.3rem 0 var(--button-outer-shadow-color, rgba(60,60,60,0.3)), inset 0 0.1rem 0.3rem 0 transparent;
            background: ${theme && theme.hover}
        };
        &:active {
            --button-inner-shadow-color: rgba(60,60,60,0.4);
            box-shadow: 0 0.2rem 0.3rem 0 var(--button-outer-shadow-color, rgba(60,60,60,0.3)), inset 0 0.1rem 0.3rem 0 var(--button-inner-shadow-color, rgba(60,60,60,0.4));
        }
    `
};

  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0.3rem;
  
  border: 1px solid #333333;

  //box-shadow: 0 0.2rem 0.3rem 0 transparent, inset 0 0.1rem 0.3rem 0 transparent;
  box-shadow: 0 4px 6px -2px rgba(0,0,0,0.1);
  transition: box-shadow ease-out .2s, background-color ease-out .2s;
`;

export const Button = styled(WrappedButton)`
  ${ButtonStyle};
`;

export const BorderedButton = styled(Button)`
    ${({theme}: ButtonProps) => theme
    ? css`
          border: 1px solid ${theme.text};
          &:hover {
            background: ${theme.color};
          }`
    : ''
};
`;

export const TunasecButton = styled(Button)`
  margin-top: 10px;
  background-color: #31004a;
  color: #ffffff;
  
  &:hover {
    color: #ffffff;
  }

  ${media.tablet`
        display: flex;
        width: fit-content;
        margin: 10px auto;
    `}
`