import styled from "styled-components";

export const TopPhotoFrame = styled.div`
  background-image: url("/img/layout/background-main.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% );
  background-position: top left;
  background-size: 1920px;
  height: 350px;
`;

export const TopPhotoText = styled.div`
  display: flex;
  height: 150px;
  line-height: 1;
  justify-content: space-around;
  flex-direction: column;
`;

export const MainSection = styled.section`
  min-height: 60vh;
  margin-bottom: 60px;
`;
