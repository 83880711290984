import {css} from 'styled-components';

export type BreakpointDefinition = {
    min: string,
    max: string
}

export type Size = "mobile" | "tablet" | "desktop" | "bigscreen";

export const breakpoints: { [key in Size]: BreakpointDefinition } = {
    mobile: {
        min: '0',
        max: '720px'
    },
    tablet: {
        min: '720px',
        max: '960px'
    },
    desktop: {
        min: '960px',
        max: '1170px'
    },
    bigscreen: {
        min: '1170px',
        max: '999999px'
    }
};

export const media = {
    mobile: (s, ...i) => css`@media (max-width: ${breakpoints.mobile.max}) { ${css(s, ...i)} }`,
    tablet: (s, ...i) => css`@media (max-width: ${breakpoints.tablet.max}) { ${css(s, ...i)} }`,
    desktop: (s, ...i) => css`@media (max-width: ${breakpoints.desktop.max}) { ${css(s, ...i)} }`,
    bigscreen: (s, ...i) => css`@media (max-width: ${breakpoints.bigscreen.max}) { ${css(s, ...i)} }`
};

export type ThemeDefinition = {
    color: string,
    hover: string,
    text: string
}

export const themes = {
    link: {
        color: '#2FA6FD',
        hover: '#2b99e9',
        text: '#fff'
    },
    main: {
        color: '#00A4FF',//'#3055B1',
        hover: '#467bcb',
        text: '#fff'
    },
    secondary: {
        color: '#1F5581',
        hover: '#114473',
        text: '#fff'
    },
    secondaryDark: {
        color: '#15395C',
        hover: '#114473',
        text: '#fff'
    },
    light: {
        color: '#F8F8FA',
        hover: '#fff',
        text: '#444444'
    },
    panel: {
        color: '#FFF',
        hover: '#F2F2F2',
        text: '#424242'
    },
    danger: {
        color: '#D0021B',
        hover: '#e43321',
        text: '#fff'
    },
    success: {
        color: '#05D384', //'#05A54F',
        hover: '#21c05d',
        text: '#fff'
    },
    disabled: {
        color:'#fff',
        hover:'#B9B9B9',
        text:'#9F9F9F',
    },
    darkPanel: {
        color: "#424242",
        hover: "#666",
        text: "#FFF"
    }
};